import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/Slider";
import About from "../../components/about/About";
import Workexperience from "../../components/workexperience/Workexperience";
import FrameDQuote from "../../components/framedquote/FrameDQuote";
import Skills from "../../components/skills/Skills";
import Certifications from "../../components/certifications/Certifications";
import VideoResume from "../../components/video/VideoResume";
import Brand from "../../components/Brand";
import Testimonial from "../../components/testimonial/Testimonial";
import ExtraCirricular from "../../components/extracirricular/ExtraCirricular";
import CallToAction from "../../components/calltoactions/CallToAction";
import Footer from "../../components/footer/Footer";
import Reveal from "react-reveal/Reveal";

const Home = () => {
  return (
    <div className="home-two">
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Slider Section */}

      <About />
      {/* End About Section */}

      <Skills />
      {/* End Skills Section */}

      {/* <Certifications /> */}
      {/* End Skills Section */}

      <div className="shivani_tm_section">
        <div className="shivani_tm_testimonials">
          <div className="container">
            <div className="testimonials_inner">
              <Reveal effect="fadeInRight">
                <div className="left">
                  <div className="shivani_tm_title">
                    <span>Certifications</span>
                    {/* <h3>A word from former colleagues</h3> */}
                  </div>
                </div>
              </Reveal>
              <Reveal effect="fadeInRight">
                <div className="right">
                  <Certifications />
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>

      <VideoResume />
      {/* End Video Section */}

      <Workexperience />
      {/**End WorkExp Section */}

      <div className="shivani_tm_section">
        <div className="shivani_tm_partners">
          <div className="container">
            <div className="partners_inner">
              <Brand />
            </div>
          </div>
        </div>
      </div>
      {/* End shivani_tm_partners */}

      <div className="shivani_tm_section">
        <div className="shivani_tm_testimonials">
          <div className="container">
            <div className="testimonials_inner">
              <Reveal effect="fadeInRight">
                <div className="left">
                  <div className="shivani_tm_title">
                    <span>Testimonials</span>
                    <h3>A word from former colleagues</h3>
                  </div>
                </div>
              </Reveal>
              <Reveal effect="fadeInRight">
                <div className="right">
                  <Testimonial />
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
      {/* End  shivani_tm_testimonials*/}

      <ExtraCirricular />
      {/* End ExtraCirricular Section */}

      <FrameDQuote />
      {/* End FrameDQuote Section */}

      <CallToAction />
      {/* End CallToAction */}

      <Footer />
      {/* End Footer Section */}
    </div>
  );
};

export default Home;
