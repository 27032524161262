import React, { useState } from "react";
import Modal from "react-modal";
import Reveal from "react-reveal/Reveal";

Modal.setAppElement("#root");

const Workexperience = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }

  return (
    <div className="shivani_tm_section" id="workexperience">
      <div className="shivani_tm_news">
        <div className="container">
          <div className="shivani_tm_title">
            <span>Work Experience</span>
            {/* <h5>Click for more info</h5> */}
          </div>
          {/* End shivani_tm_title */}
          <div className="news_list">
            <ul>
              <Reveal effect="fadeInLeft">
                <li>
                  <div className="list_inner">
                    <div className="image" onClick={toggleModalOne}>
                      <img src="/img/placeholders/4-3.jpg" alt="hasmukh" />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL + "img/news/hasmukh.jpeg"
                          })`,
                          backgroundSize: "contain",
                        }}
                      ></div>
                    </div>
                    {/* End image */}

                    <div className="details">
                      <h3 className="company" onClick={toggleModalOne}>
                        Hasmukh Lalbhai Share Broker
                      </h3>
                      <br></br>
                      <h5 className="title">Equity Research Analyst</h5>
                      <p className="date">
                        <span>May 2021 - July 2021</span>
                      </p>
                    </div>
                    {/* End details */}

                    {/* START MODAL */}
                    <Modal
                      isOpen={isOpen}
                      onRequestClose={toggleModalOne}
                      contentLabel="My dialog"
                      className="custom-modal"
                      overlayClassName="custom-overlay"
                      closeTimeoutMS={500}
                    >
                      <div className="shivani_tm_modalbox_news">
                        <button
                          className="close-modal"
                          onClick={toggleModalOne}
                        >
                          <img src="/img/svg/cancel.svg" alt="close icon" />
                        </button>
                        {/* END CLOSE MODAL */}
                        <div className="box_inner">
                          <div className="description_wrap scrollable">
                            <div className="image">
                              <img
                                src="/img/placeholders/4-3.jpg"
                                alt="hasmukh"
                              />
                              <div
                                className="main"
                                style={{
                                  backgroundImage: `url(${
                                    process.env.PUBLIC_URL +
                                    "img/news/hasmukh.jpeg"
                                  })`,
                                  backgroundSize: "contain",
                                }}
                              ></div>
                            </div>
                            {/* END IMAGE */}
                            <div className="details">
                              <h3 className="company">
                                Hasmukh Lalbhai Share Broker
                              </h3>
                              <h5 className="title">Equity Research Analyst</h5>
                              <p className="date">
                                <span>May 2021 - July 2021</span>
                              </p>
                            </div>
                            {/* END DETAILS */}
                            <div className="description">
                              <p>
                                Equity research and in-depth analysis of 15
                                pharmaceutical vaccine manufacturing companies,
                                including Dr. Reddy Laboratories, Cadila
                                healthcare & Pfizer to mention a few.{" "}
                              </p>
                              <blockquote>
                                • The study contains an EIC, SWOT, PESTEL, and
                                VUCA analysis of the sector, as well as specific
                                product information and production factors.
                              </blockquote>
                              <p>
                                • The companies under study were subjected to
                                SWOT analysis, ratio analysis, fund flow
                                analysis, valuation, and forecasting using the
                                percent sales method.
                              </p>
                            </div>
                            {/* END DESCRIPTION */}
                            {/* <div className="news_share">
                              <Social />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </li>
                {/* End single blog */}
              </Reveal>
              <Reveal effect="fadeInLeft">
                <li>
                  <div className="list_inner">
                    <div className="image" onClick={toggleModalTwo}>
                      <img src="/img/placeholders/4-3.jpg" alt="outllokgroup" />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL +
                            "img/news/outlookgroup.jpeg"
                          })`,
                          backgroundSize: "contain",
                        }}
                      ></div>
                    </div>
                    {/* End image */}

                    <div className="details">
                      <h3 className="company" onClick={toggleModalTwo}>
                        Outlook Group
                      </h3>
                      <br></br>
                      <h5 className="title">Research Analyst</h5>
                      <p className="date">
                        {/* By <a href="#">Brook Kennedy</a>{" "} */}
                        <span>2021</span>
                      </p>
                    </div>
                    {/* End details */}

                    {/* End modal */}
                  </div>
                  {/* End list inner */}
                </li>
                {/* End single blog */}
              </Reveal>
              <Reveal effect="fadeInLeft">
                <li>
                  <div className="list_inner">
                    <div className="image" onClick={toggleModalThree}>
                      <img src="/img/placeholders/4-3.jpg" alt="hdfc" />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL + "img/news/hdfc.webp"
                          })`,
                          backgroundSize: "contain",
                        }}
                      ></div>
                    </div>
                    {/* END IMAGE */}

                    <div className="details">
                      <h3 className="company" onClick={toggleModalThree}>
                        HDFC Securities
                      </h3>
                      <br></br>
                      <h5 className="title">Relationship Manager</h5>
                      <p className="date">
                        <span>May 2019 - Dec 2019</span>
                      </p>
                    </div>
                    {/* END DETAILS */}

                    {/* START MODAL */}
                    <Modal
                      isOpen={isOpen3}
                      onRequestClose={toggleModalThree}
                      contentLabel="My dialog"
                      className="custom-modal"
                      overlayClassName="custom-overlay"
                      closeTimeoutMS={500}
                    >
                      <div className="shivani_tm_modalbox_news">
                        <button
                          className="close-modal"
                          onClick={toggleModalThree}
                        >
                          <img src="/img/svg/cancel.svg" alt="close icon" />
                        </button>
                        {/* END CLOSE MODAL */}
                        <div className="box_inner">
                          <div className="description_wrap scrollable">
                            <div className="image">
                              <img src="/img/placeholders/4-3.jpg" alt="hdfc" />
                              <div
                                className="main"
                                style={{
                                  backgroundImage: `url(${
                                    process.env.PUBLIC_URL +
                                    "img/news/hdfc.webp"
                                  })`,
                                  backgroundSize: "contain",
                                }}
                              ></div>
                            </div>
                            {/* END IMAGE */}
                            <div className="details">
                              <h3 className="company">HDFC Securities</h3>
                              <h5 className="title">Relationship Manager</h5>
                              <p className="date">
                                <span>May 2019 - Dec 2019</span>
                              </p>
                            </div>
                            {/* END DETAILS */}
                            <div className="description">
                              <p>Handling Portfolios of customers</p>
                              <blockquote>
                                • Daily targets were achieved regularly.
                              </blockquote>
                              <p>Write more about duties.</p>
                            </div>
                            {/* END DESCRIPTION */}
                            {/* <div className="news_share">
                              <Social />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                  {/* END LIST INNER */}
                </li>
              </Reveal>
              {/* End single blog */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workexperience;
