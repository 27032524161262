import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplayspeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <ul>
      <Slider {...settings}>
        <li className="item">
          <img src="/img/skills/dataAnalytics.png" alt="partners brand" />
        </li>

        <li className="item">
          <img src="/img/skills/python.png" alt="partners brand" />
        </li>

        <li className="item">
          <img src="/img/skills/swotAnalysis.png" alt="partners brand" />
        </li>

        <li className="item">
          <img src="/img/skills/teamWork.png" alt="partners brand" />
        </li>

        <li className="item">
          <img src="/img/skills/timeManagement.png" alt="partners brand" />
        </li>

        <li className="item">
          <img src="/img/skills/creativity.png" alt="partners brand" />
        </li>

        <li className="item">
          <img src="/img/skills/microsoftOffice.png" alt="partners brand" />
        </li>

        {/* <li className="item">
          <img src="/img/partners/8.png" alt="partners brand" />
        </li> */}
      </Slider>
    </ul>
  );
}
