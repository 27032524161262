import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialTwo() {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <ul>
      <Slider {...settings}>
        <li className="item">
          <div className="texts">
            <p>
              Shivani worked under my team as a Finance Data Analyst for
              customer analysis project. Her technical knowledge and guage at
              analysing data and identifying patterns to get insights useful to
              increase the customer satisfaction were highly appreciated by the
              team and the higher management.
            </p>
            <div className="author">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/testimonials/omkarsali.jpeg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author">
                  <span>Omkar Sali</span>
                </h3>
                <h3 className="job">
                  <span>Product Owner at Optum</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}

        <li className="item">
          <div className="texts">
            <p>
              I worked with Shivani for over 6 months. Lorem ipsum dolor sit
              amet consectetur, adipisicing elit. Impedit quidem temporibus nisi
              ipsam enim neque nihil nostrum odio consequuntur! Quo.
            </p>
            <div className="author">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/2.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author">
                  <span>Mrunal Shirke</span>
                </h3>
                <h3 className="job">
                  <span>Manager at HDFC Securities</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}

        <li className="item">
          <div className="texts">
            <p>
              I mentored Shivani during her summer project where Shivani and her
              team analyzed companies manufacturing vaccines. Lorem ipsum dolor
              sit amet consectetur adipisicing elit. Officiis autem repellendus
              veritatis delectus commodi? Rem.
            </p>
            <div className="author">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/3.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author">
                  <span>Farida Fernandez</span>
                </h3>
                <h3 className="job">
                  <span>Professor at MET</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}
      </Slider>
    </ul>
  );
}
