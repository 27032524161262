import React from "react";
import Reveal from "react-reveal/Reveal";

const About = () => {
  return (
    <>
      <div className="shivani_tm_section" id="about">
        <div className="shivani_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div className="image">
                  <img src="/img/placeholders/1-1.jpg" alt="placeholder" />
                  <Reveal effect="fadeInLeft">
                    <div
                      className="main"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL + "img/about/about.jpg"
                        })`,
                      }}
                    ></div>
                  </Reveal>
                </div>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <Reveal effect="fadeInLeft">
                  <div className="shivani_tm_title">
                    <span>About Me</span>
                    <h3>Masters of Management in Finance from MET, Mumbai</h3>
                  </div>
                  <div className="text">
                    <p>
                      I'm an MBA Finance student at MET. With around a year
                      experience as a security risk analyst , I have acquired
                      the skills and knowledge necessary to make your financial
                      journey a success.
                    </p>
                  </div>
                  <div className="shivani_tm_button">
                    <a
                      href="/assets/ShivaniSali.docx"
                      download="Shivani Sali Resume"
                    >
                      Download CV
                    </a>
                  </div>
                </Reveal>
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default About;
