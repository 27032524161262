import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Certifications() {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <ul>
      <Slider {...settings}>
        <li className="item">
          <div className="texts">
            <p>
              Gained an understanding of the Mutual Fund Industry in India.
              Understood the structure of MFs. regulators, & intermediaries.
              Conducted by National Institute of Securities Markets, expires
              August 2022.
            </p>
            <div className="author">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/1.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author">
                  <span>NISM Series V A: Mutual Funds Distributors</span>
                </h3>
                <h3 className="job">
                  <span>August 2019</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}

        <li className="item">
          <div className="texts">
            <p>
              Gained an understanding of Accounting and Financial Statements,
              Financial Ratios, Understood the importance of Industry Cycles,
              important drivers for an Industry. Conducted by 365 Careers and
              Udemy.
            </p>
            <div className="author">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/certifications/udemy.png"
                    })`,
                    backgroundSize: "contain",
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author">
                  <span>Financial Analyst Course</span>
                </h3>
                <h3 className="job">
                  <span>July 2020</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}

        <li className="item">
          <div className="texts">
            <p>
              The basics of digital marketing with free Interactive Advertising
              Bureau-accredited course. Gained knowledge about SEO, SEM, Social
              Media Marketing, Email Marketing. Certified by Google Digital
              Unlocked.
            </p>
            <div className="author">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/certifications/google.png"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author">
                  <span>Fundamentals of Digital Marketing</span>
                </h3>
                <h3 className="job">
                  <span>May 2020</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}
      </Slider>
    </ul>
  );
}
