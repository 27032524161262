import React, { useState } from "react";
import Modal from "react-modal";
import Reveal from "react-reveal/Reveal";

Modal.setAppElement("#root");

const ExtraCirricular = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }

  return (
    <div className="shivani_tm_section" id="news">
      <div className="shivani_tm_news">
        <div className="container">
          <div className="shivani_tm_title">
            <span>Extra Curriculars</span>
            <h3>Checkout some of my other works</h3>
          </div>
          {/* End shivani_tm_title */}
          <div className="news_list">
            <ul>
              <Reveal effect="fadeInLeft">
                <li>
                  <div className="list_inner">
                    <div className="image" onClick={toggleModalOne}>
                      <img
                        src="/img/placeholders/4-3.jpg"
                        alt="youtubechannel"
                      />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL + "img/news/youtube.png"
                          })`,
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.youtube.com/channel/UCKaefrHti26EKiW8ZMPMNmg",
                            "_blank"
                          )
                        }
                      ></div>
                    </div>
                    {/* End image */}

                    <div className="details">
                      <h3 className="title" onClick={toggleModalOne}>
                        Youtube Channel
                      </h3>
                      <p className="date">
                        {/* By <a href="#">Alex Watson</a>  */}
                        <span>EST. 25 Aug 2015</span>
                      </p>
                    </div>
                    {/* End details */}
                  </div>
                </li>
                {/* End single blog */}
              </Reveal>
              <Reveal effect="fadeInLeft">
                <li>
                  <div className="list_inner">
                    <div className="image" onClick={toggleModalTwo}>
                      <img src="/img/placeholders/4-3.jpg" alt="framdquote" />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL + "img/news/frameDQuote.jpeg"
                          })`,
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/framedquote/",
                            "_blank"
                          )
                        }
                      ></div>
                    </div>
                    {/* End image */}

                    <div className="details">
                      <h3 className="title" onClick={toggleModalTwo}>
                        The solution for all your gifting problems.
                      </h3>
                      <p className="date">
                        {/* By <a href="#">Brook Kennedy</a>{" "} */}
                        <span>Est. 18 Sept 2019</span>
                      </p>
                    </div>
                    {/* End details */}

                    {/* End modal */}
                  </div>
                  {/* End list inner */}
                </li>
                {/* End single blog */}
              </Reveal>
              <Reveal effect="fadeInLeft">
                <li>
                  <div className="list_inner">
                    <div className="image" onClick={toggleModalThree}>
                      <img src="/img/placeholders/4-3.jpg" alt="singing" />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL + "img/news/dimensions.jpeg"
                          })`,
                        }}
                      ></div>
                    </div>
                    {/* END IMAGE */}

                    <div className="details">
                      <h3 className="title" onClick={toggleModalThree}>
                        Hospitality Committee
                      </h3>
                      {/* <p className="date">
                        By <a href="#">Paola Atkins</a>{" "}
                        <span>02 March 2020</span>
                      </p> */}
                    </div>
                    {/* END DETAILS */}

                    {/* START MODAL */}
                    <Modal
                      isOpen={isOpen3}
                      onRequestClose={toggleModalThree}
                      contentLabel="My dialog"
                      className="custom-modal"
                      overlayClassName="custom-overlay"
                      closeTimeoutMS={500}
                    >
                      <div className="shivani_tm_modalbox_news">
                        <button
                          className="close-modal"
                          onClick={toggleModalThree}
                        >
                          <img src="/img/svg/cancel.svg" alt="close icon" />
                        </button>
                        {/* END CLOSE MODAL */}
                        <div className="box_inner">
                          <div className="description_wrap scrollable">
                            <div className="image">
                              <img
                                src="/img/placeholders/4-3.jpg"
                                alt="thumb"
                              />
                              <div
                                className="main"
                                style={{
                                  backgroundImage: `url(${
                                    process.env.PUBLIC_URL +
                                    "img/news/dimensions.jpeg"
                                  })`,
                                }}
                              ></div>
                            </div>
                            {/* END IMAGE */}
                            <div className="details">
                              <h3 className="title">Dimensions Committee</h3>
                              <p className="date">
                                <span>05 May 2010</span>
                              </p>
                            </div>
                            {/* END DETAILS */}
                            <div className="description">
                              <p>
                                Part of the publicity committee in the
                                Dimensions festival for Vaze Kelkar College,
                                Mulund.
                              </p>
                              <blockquote>
                                Participated in dimensions Lorem ipsum dolor sit
                                amet consectetur adipisicing elit. Tempora sed
                                culpa nostrum omnis veritatis nesciunt sapiente,
                                cupiditate numquam suscipit voluptas.
                              </blockquote>
                              <p>
                                Performed classical singing with group and won
                                the award in the regional competition.
                              </p>
                            </div>
                            {/* END DESCRIPTION */}
                            {/* <div className="news_share">
                              <Social />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                  {/* END LIST INNER */}
                </li>
              </Reveal>
              {/* End single blog */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraCirricular;
