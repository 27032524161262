import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Reveal from "react-reveal/Reveal";

const FrameDQuote = () => {
  return (
    <div className="shivani_tm_section" id="framedquote">
      <SimpleReactLightbox>
        <div className="shivani_tm_portfolio">
          <div className="container">
            <div className="positon-relative">
              <div className="shivani_tm_title">
                <div className="title_flex">
                  <div className="left">
                    <span>Passion Project</span>
                    <h3>FrameDQuote</h3>
                  </div>
                </div>
              </div>
              {/* End shivani_tm_title */}
              <div className="portfolio_filter">
                <Tabs>
                  <TabList>
                    <Tab>All</Tab>
                    <Tab>Design</Tab>
                    <Tab>Quotes</Tab>
                    <Tab>Series</Tab>
                  </TabList>
                  {/* End tablist */}
                  <div className="portfolio_list">
                    <SRLWrapper>
                      <TabPanel>
                        <ul className="gallery_zoom">
                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shivani_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/fdq1.jpg">
                                    <img
                                      src="/img/portfolio/fdq1.jpg"
                                      alt="Design"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Gleam & Glow</h3>
                                  <span>Quote</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shivani_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/fdq6.jpg">
                                    <img
                                      src="/img/portfolio/fdq6.jpg"
                                      alt="Photography"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Dreams</h3>
                                  <span>Design</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shivani_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/fdq3.jpg">
                                    <img
                                      src="/img/portfolio/fdq3.jpg"
                                      alt="Photography"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Take Risks</h3>
                                  <span>Quote</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shivani_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/fdq4.jpg">
                                    <img
                                      src="/img/portfolio/fdq4.jpg"
                                      alt="Design"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Work Hard</h3>
                                  <span>Quote</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shivani_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/fdq5.jpg">
                                    <img
                                      src="/img/portfolio/fdq5.jpg"
                                      alt="Branding"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Never give up</h3>
                                  <span>Quote</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shivani_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/fdq2.jpg">
                                    <img
                                      src="/img/portfolio/fdq2.jpg"
                                      alt="Branding"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Breaking Bad</h3>
                                  <span>Series</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}
                        </ul>
                        {/* End portfolio list */}
                      </TabPanel>
                      {/* END ALL PORTFOLIO GALLERY */}

                      <TabPanel>
                        <ul className="gallery_zoom">
                          <li>
                            <div className="inner">
                              <div className="entry shivani_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/fdq1.jpg">
                                  <img
                                    src="/img/portfolio/fdq1.jpg"
                                    alt="Design"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Gleam & Glow</h3>
                                <span>Quote</span>
                              </div>
                            </div>
                          </li>
                          {/* End single image block */}

                          <li>
                            <div className="inner">
                              <div className="entry shivani_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/fdq6.jpg">
                                  <img
                                    src="/img/portfolio/fdq6.jpg"
                                    alt="Photography"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Dreams</h3>
                                <span>Design</span>
                              </div>
                            </div>
                          </li>
                          {/* End single image block */}
                        </ul>
                        {/* End portfolio list */}
                      </TabPanel>

                      <TabPanel>
                        <ul className="gallery_zoom">
                          <li>
                            <div className="inner">
                              <div className="entry shivani_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/fdq3.jpg">
                                  <img
                                    src="/img/portfolio/fdq3.jpg"
                                    alt="Photography"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Take Risks</h3>
                                <span>Quote</span>
                              </div>
                            </div>
                          </li>
                          {/* End single image block */}

                          <li>
                            <div className="inner">
                              <div className="entry shivani_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/fdq4.jpg">
                                  <img
                                    src="/img/portfolio/fdq4.jpg"
                                    alt="Design"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Work Hard</h3>
                                <span>Quote</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                        {/* End single image block */}
                      </TabPanel>

                      <TabPanel>
                        <ul className="gallery_zoom">
                          <li>
                            <div className="inner">
                              <div className="entry shivani_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/fdq5.jpg">
                                  <img
                                    src="/img/portfolio/fdq5.jpg"
                                    alt="Branding"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Never give up</h3>
                                <span>Quote</span>
                              </div>
                            </div>
                          </li>
                          {/* End single image block */}

                          <li>
                            <div className="inner">
                              <div className="entry shivani_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/fdq2.jpg">
                                  <img
                                    src="/img/portfolio/fdq2.jpg"
                                    alt="Branding"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Breaking Bad</h3>
                                <span>Series</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                        {/* End single image block */}
                      </TabPanel>
                    </SRLWrapper>
                    {/* End tabpanel */}
                  </div>
                  {/* End list wrapper */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </SimpleReactLightbox>
    </div>
  );
};

export default FrameDQuote;
